/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CategoryDto,
} from '../models/index';
import {
    CategoryDtoFromJSON,
    CategoryDtoToJSON,
} from '../models/index';

export interface FindCategoriesRequest {
    xTenantID: string;
    restaurantId: string;
    language?: string;
}

/**
 * 
 */
export class CategoryApi extends runtime.BaseAPI {

    /**
     * get categories by menu id
     */
    async findCategoriesRaw(requestParameters: FindCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        if (requestParameters.xTenantID === null || requestParameters.xTenantID === undefined) {
            throw new runtime.RequiredError('xTenantID','Required parameter requestParameters.xTenantID was null or undefined when calling findCategories.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling findCategories.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantID !== undefined && requestParameters.xTenantID !== null) {
            headerParameters['X-TenantID'] = String(requestParameters.xTenantID);
        }

        const response = await this.request({
            path: `/api/{restaurantId}/categories`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * get categories by menu id
     */
    async findCategories(requestParameters: FindCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto>> {
        const response = await this.findCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
