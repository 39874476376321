import styled from "@emotion/styled";
import {HeaderMenu} from "./HeaderMenu";
import {Divider} from "./Divider";
import {useContext, useEffect, useRef, useState} from "react";
import { findCurrentRestaurant } from "../../service/ClientConnect";
import { RestaurantDto } from "../../generated-sources/client";
import Language from "../language/Language";
import {LanguageContext} from "../../contexts/LanguageContext";
import {Contact} from "./Contact";
import {apiBasePath} from "../../utils/ConfigUtils";
import {FeaturesContext} from "../../contexts/FeaturesContext";


interface HeaderProps {
    buildBackground:()=> string;
}
const HeaderWrapper = styled.header<HeaderProps>`
    & .banner {
        ${props => props.buildBackground()}
        padding-top: 0.5rem;
        position: relative;
        background-size: cover;
        min-height: 6rem;
        text-align: center;
        font-size: 1.25rem;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    & .banner .description {
        font-family: "Bodoni MT", serif;
        text-transform: uppercase;
        font-size: 1rem;
    }


    & .logo {
        background-color: black;
        height: 65px;
        width: 65px;
        position: absolute;
        top: 70px;
        left: 1rem;
        border-radius: 50px;
    }

    & .title {
        padding-top: 25px;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        font-size: 1.3rem;
        font-weight: bold;
        font-family: Sans-serif,Poppins,serif;
        color: #282A33
    }
    
    & .btn-wrapper {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        display: flex;
    }
    
    & .sticky-menu {
        width: 100%;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        padding: 0.5rem 0 0 0;

    }

    & .sticky-menu-wrapper {
        height: 45px ;
    }

    & .sticky {
        z-index: 100;
        position: fixed;
        top: 0;
    }
`;

export function Header() {
    const basePath = apiBasePath;
    const refMenu = useRef<HTMLDivElement>(null);
    const stickyPoint = useRef<number>();
    const [restaurant,setRestaurant] = useState<RestaurantDto>()
    const {currentLanguage: language, setLanguage } = useContext(LanguageContext);
    const {setFeatures} = useContext(FeaturesContext);


    useEffect(() => {
        stickyPoint.current = refMenu.current?.offsetTop;
        window.addEventListener('scroll', stickyHeaderMenu);
        return () => {
            window.removeEventListener('scroll', stickyHeaderMenu);
        };
    }, []);
    useEffect(() => {
        findCurrentRestaurant(language!!).then(response => {
            setFeatures({
                orderEnabled: response.orderEnabled ?? false
            })
            setRestaurant(response)}
        )
    }, [language, setFeatures]);

    const stickyHeaderMenu = () => {

        if (window.scrollY >= stickyPoint.current!) {
            refMenu.current?.classList.add('sticky');
        } else {
            refMenu.current?.classList.remove('sticky');
        }
    };

    const buildBackground = () => {
        if(restaurant?.banner) return `background: url(${basePath}${restaurant?.banner}) center;`
        return '';
    }

    return (
        <HeaderWrapper buildBackground={buildBackground}>
            <div className={'banner global-banner'}>
                <span className={'description'}>{restaurant?.headerText}</span>
                {restaurant?.logo && <img className={'logo'} src={`${basePath}${restaurant?.logo}`} alt={'logo'}/>}
                <div className="btn-wrapper">
                    <Contact restaurant={restaurant!} buildBackground={buildBackground}></Contact>
                    <Language setLanguage={setLanguage}/>
                </div>
            </div>
            <h1 className={'title'}>{restaurant?.name}</h1>
            <div id='category-top-page'></div>
            <div className={'sticky-menu-wrapper'}>
                <div className="sticky-menu" ref={refMenu}>
                    <HeaderMenu />
                    <Divider/>
                </div>
            </div>
        </HeaderWrapper>
    );
}