/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    language?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    menuId?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    icon?: string;
}

/**
 * Check if a given object implements the CategoryDto interface.
 */
export function instanceOfCategoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CategoryDtoFromJSON(json: any): CategoryDto {
    return CategoryDtoFromJSONTyped(json, false);
}

export function CategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'menuId': !exists(json, 'menuId') ? undefined : json['menuId'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function CategoryDtoToJSON(value?: CategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'language': value.language,
        'menuId': value.menuId,
        'icon': value.icon,
    };
}

