import styled from "@emotion/styled";

const DividerWrapper = styled.div`
    & hr {
        margin: 0;
        border: 0;
        border-top: 1px solid #8E8EA9;
    }
`;

export function Divider() {
    return (
        <DividerWrapper>
            <hr />
        </DividerWrapper>
    );
} 