import styled from "@emotion/styled";
import {FontIcon} from "@react-md/icon";
import {Search} from "../../components/Search";
import {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {MenuItemDto} from "../../generated-sources/client";
import {findMenuItemsByName} from "../../service/ClientConnect";
import {MenuItemCard} from "../../components/MenuItemCard";
import {useNavigate} from "react-router-dom";
import search from "../../assets/images/search.svg"
import {useTranslation} from "react-i18next";
import {LanguageContext} from "../../contexts/LanguageContext";
import {debounce} from "lodash";
import {Loader} from "../../components/loader/Loader";

const BoxWrapper = styled.div`
    background-color: #F6F6F9;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;

    & .items {
        padding: 1rem;
    }
`;

const TopBar = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 10px;
`;

const ReturnBtn = styled.button`
    width: 40px;
    height: 40px;
    background-color: white;
    color: black;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
`;

const StyledArrowLeft = styled(FontIcon)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CenteredImageWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin-top: 50px;
    flex-direction: column; /* Align children vertically */

`;

export function SearchView() {

    const [items, setItems] = useState<MenuItemDto[]>([])
    const [showLoader, setShowLoader] = useState<boolean>()
    const navigate = useNavigate();
    const {t} = useTranslation<string, string>();
    const searchInput = useRef<any>();


    const {currentLanguage: language} = useContext(LanguageContext);
    const handleBackButtonClick = () => {
        window.history.back();

    };
    const handleOnChange = debounce(async (input: ChangeEvent<HTMLInputElement>) => {
        try {
            setShowLoader(true);
            const result = await findMenuItemsByName(input.target.value, language);
            setItems(result)
        } finally {
            setShowLoader(false);
        }
    }, 400);
    const openDetails = async (menuItem: MenuItemDto) => {
        navigate(`/categories/menuItems/${menuItem.id}/details`, {state: {menuItem: menuItem, backSteps : -2}});

    }

    useEffect(() => {
        searchInput.current?.focus();
    }, []);

    return (
        <BoxWrapper>
            <TopBar>
                <ReturnBtn onClick={handleBackButtonClick}>
                    <StyledArrowLeft>
                        arrow_back
                    </StyledArrowLeft>
                </ReturnBtn>
                <Search inputRef={searchInput} onChange={handleOnChange}/>
            </TopBar>

            {searchInput.current?.value.length === 0 && items.length === 0 && !showLoader && <CenteredImageWrapper>
                <img src={search} alt="Centered"/>
                <p>{t("Looking for something ?")}</p>
                <p style={{color: "#8E8EA9"}}>{t("Search for it and we will help find it.")}</p>
            </CenteredImageWrapper>}

            {searchInput.current?.value.length > 0 && items.length === 0 && !showLoader && <CenteredImageWrapper>
                <img src={search} alt="Centered"/>
                <p>{t("No results found")}</p>
            </CenteredImageWrapper>}

            <div className="items">
                {!showLoader && items?.map((menuItem) =>
                    <MenuItemCard key={menuItem.id} menuItem={menuItem} style={{marginBottom: "15px"}}
                                  onClick={() => openDetails(menuItem)}/>)}
            </div>

            {showLoader && <CenteredImageWrapper><Loader/></CenteredImageWrapper>}
        </BoxWrapper>
    );
}
