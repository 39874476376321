import React, {useState} from "react";
import {RouterProvider} from "react-router-dom";
import {LanguageContext} from "./contexts/LanguageContext";
import {router} from "./router";
import {useTranslation} from "react-i18next";
import {languagesMap} from "./components/language/LangUtils";
import {Features, FeaturesContext} from "./contexts/FeaturesContext";

export function App() {
    const {i18n} = useTranslation<string, string>();
    const [language, setLanguage] = useState<string>(languagesMap.get(i18n.language)!!)
    const [features, setFeatures ] = useState<Features>({orderEnabled: false})
    return (
        <React.StrictMode>
            <LanguageContext.Provider value={{currentLanguage: language, setLanguage: setLanguage}}>
                <FeaturesContext.Provider value={{features: features, setFeatures: setFeatures}}>
                    <RouterProvider router={router}/>
                </FeaturesContext.Provider>
            </LanguageContext.Provider>
        </React.StrictMode>
    );
}