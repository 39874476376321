import {useLocation} from "react-router-dom";
import {MenuItemDetails} from "../../components/MenuItemDetails";

export function MenuItemDetailsView() {

    const location = useLocation();

    return (
        <MenuItemDetails menuItem={location.state?.menuItem} />
    );
}