import {CategoryApi, Configuration, MenuItemApi, RestaurantApi, HomeCategoryApi} from "../generated-sources/client";
import { readCurrentRestaurantIdentifier } from "./StorageService";
import { apiBasePath } from "../utils/ConfigUtils";

const configuration = new Configuration({ basePath: apiBasePath });
export const categoryApi = new CategoryApi(configuration);
export const menuItemApi = new MenuItemApi(configuration);
export const restaurantApi = new RestaurantApi(configuration);
export const homeCategoryApi = new HomeCategoryApi(configuration);
export const findCategories = async (language: string) => {
    const restaurantIdentifier = readCurrentRestaurantIdentifier();
    return categoryApi.findCategories({
        xTenantID: restaurantIdentifier.tenantId!!,
        restaurantId: restaurantIdentifier.restaurantId!!,
        language: language!!
    });
}


export const findHomeCategories = async (language?: string) => {
    const restaurantIdentifier = readCurrentRestaurantIdentifier();
    return homeCategoryApi.findHomeCategories({
        xTenantID: restaurantIdentifier.tenantId!!,
        restaurantId: restaurantIdentifier.restaurantId!!,
        language: language
    });
}

export const findMenuItems = async (categoryId: number, language: string) => {
    const restaurantIdentifier = readCurrentRestaurantIdentifier();
    return menuItemApi.findMenuItemsByCategoryId({
        pageSize: 100,
        pageNumber: 0,
        xTenantID: restaurantIdentifier.tenantId!!,
        restaurantId: restaurantIdentifier.restaurantId!!,
        categoryId: categoryId,
        language: language
    });
}

export const findCurrentRestaurant = async (language: string) => {
    const restaurantIdentifier = readCurrentRestaurantIdentifier();
    return restaurantApi.getRestaurantById({
        xTenantID: restaurantIdentifier.tenantId!!,
        restaurantId: restaurantIdentifier.restaurantId!!,
        language: language
    });
}

export const findTheme = async () => {
    const restaurantIdentifier = readCurrentRestaurantIdentifier();
    return restaurantApi.getTheme({
        xTenantID: restaurantIdentifier.tenantId!!,
        restaurantId: restaurantIdentifier.restaurantId!!
    });
};


export const findMenuItemsByName = async (name:string, language:string)=>{
    const restaurantIdentifier = readCurrentRestaurantIdentifier();
    return menuItemApi.getMenuItemsByName({
        xTenantID: restaurantIdentifier.tenantId!!,
        restaurantId: restaurantIdentifier.restaurantId!!,
        name:name,
        language:language
    })
}