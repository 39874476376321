/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CountryDto } from './CountryDto';
import {
    CountryDtoFromJSON,
    CountryDtoFromJSONTyped,
    CountryDtoToJSON,
} from './CountryDto';

/**
 * restaurant location details
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city?: string;
    /**
     * 
     * @type {CountryDto}
     * @memberof AddressDto
     */
    country?: CountryDto;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    gpsLocation?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    id?: number;
}

/**
 * Check if a given object implements the AddressDto interface.
 */
export function instanceOfAddressDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddressDtoFromJSON(json: any): AddressDto {
    return AddressDtoFromJSONTyped(json, false);
}

export function AddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': !exists(json, 'street') ? undefined : json['street'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : CountryDtoFromJSON(json['country']),
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'gpsLocation': !exists(json, 'gpsLocation') ? undefined : json['gpsLocation'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function AddressDtoToJSON(value?: AddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': value.street,
        'town': value.town,
        'city': value.city,
        'country': CountryDtoToJSON(value.country),
        'postalCode': value.postalCode,
        'gpsLocation': value.gpsLocation,
        'id': value.id,
    };
}

