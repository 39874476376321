import styled from "@emotion/styled";
import {NavLink, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {CategoryDto} from "../../generated-sources/client";
import {findCategories} from "../../service/ClientConnect";
import {RestaurantIdentifierNotFoundException} from "../../exceptions/RestaurantIdentifierNotFoundException";
import { FontIcon } from "@react-md/icon";
import { LanguageContext } from "../../contexts/LanguageContext";
import {useTranslation} from "react-i18next";
import {useRtl} from "../../hook/useRtl";

export const MenuWrapper = styled.nav`
    display: block;
    height: 36px;
    & ul {
        list-style: none;
        overflow-x: auto;
        white-space: nowrap;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        padding: 0 0.5rem 1rem 0.5rem;
        margin: 0;
    }

    /*Hide the scroll bar*/

    & ul::-webkit-scrollbar {
        display: none;
    }

    & a {
        text-decoration: none;
        color: #000000;
        font-size: 0.9rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;
        transition: color 0.3s;
        position: relative;
        outline: none;
        display: inline-block;
    }



    & a::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: -1px;
        left: 0;
        transition: width .3s;
    }


    @media (hover: hover) {
        & a:hover::after {
            width: 90%;
        }
    }

    & a.active::after {
        width: 90%;
    }

    & ul li {
        display: inline;
    }
`;

const MenuItemWrapper = styled.div`
    display: inline-block;
    flex-direction: row;

    & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    & > div :first-of-type {
        margin-right: 0.5rem;
    }
`;

export function HeaderMenu() {
    const [categories, setCategories] = useState<CategoryDto[]>()
    const navigate = useNavigate();

    const {currentLanguage } = useContext(LanguageContext);
    const {t} = useTranslation<string, string>();
    const {flexStyle} = useRtl();
    useEffect(() => {
        findCategories(currentLanguage!!)
            .then((categories) => setCategories(categories))
            .catch((error) => {
                if (error instanceof RestaurantIdentifierNotFoundException) {
                    navigate("/restaurants");
                } else {
                    throw error;
                }
            });
    }, [navigate,currentLanguage]);


    return (
        <MenuWrapper className="header">
                    <ul style={flexStyle}>
                        <li key='-1'>
                            <NavLink to={'/'}>
                                {MenuItem({name: t('Home'), icon: "home"})}
                            </NavLink>
                        </li>
                        {categories?.map((category) =>
                            <li key={category.id} >
                                <NavLink to={`/categories/${category.id}`}
                                         state={category}>
                                    {MenuItem({name: category.name, icon: category.icon})}
                                </NavLink>
                            </li>)}
                    </ul>
        </MenuWrapper>
    );

    function MenuItem({name, icon}: { name: string, icon?: string }) {
        return (
            <MenuItemWrapper>
                <div>
                <FontIcon>{icon ?? "local_dining"}</FontIcon>
                    <span>{name}</span>
                </div>
            </MenuItemWrapper>
        );
    }
}
