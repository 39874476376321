import {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {readRestaurantIdentifiers, RestaurantId} from "../../service/StorageService";

export function RestaurantsView() {
    const [restaurants, setRestaurants] = useState<RestaurantId[]>()
    useEffect(() => {
        const savedRestaurants = readRestaurantIdentifiers();
        setRestaurants(savedRestaurants);
    }, []);
    return (
        <>
            <h1>Restaurants</h1>
            <ul>
                {restaurants?.map((restaurant, index) => {
                    return <li key={index}><NavLink
                        to={'/' + restaurant.encodedRestaurantIdentifier}>{restaurant.name}</NavLink></li>
                })}
                {restaurants?.length === 0 && <div>
                    <li>No restaurants found</li>
                    <label htmlFor="photo" className="capture-button">Open</label>
                    <input id='photo' type="file" accept="image/*, video/*" capture="environment"/>
                </div>}

            </ul>
        </>
    );
}