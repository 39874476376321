import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import {BaseLayout} from "./components/layout/BaseLayout";
import HomeView from "./views/home/HomeView";
import DecoderView from "./views/decoder/DecoderView";
import {CategoryView} from "./views/category/CategoryView";
import {RestaurantsView} from "./views/restaurants/RestaurantsView";
import {MenuItemDetailsView} from "./views/menuItem/MenuItemDetailsView";
import { GlobalLayout } from "./components/layout/GlobalLayout";
import { SearchView } from "./views/searchView/SearchView";

export const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/:encodedIdentifier" element={<DecoderView/>}/>
                <Route path="/restaurants" element={<RestaurantsView/>}/>
                <Route element={<GlobalLayout/>}>
                    <Route path="/categories/menuItems/:menuItemId/details" element={<MenuItemDetailsView/>}/>
                    <Route path="/search" element={<SearchView/>}/>
                    <Route element={<BaseLayout/>}>
                        <Route path="/" element={<HomeView/>}/>
                        <Route path="/categories/:category" element={<CategoryView/>}/>
                    </Route>
                </Route>
            </>
        )
    );