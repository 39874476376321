/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MenuItemDto } from './MenuItemDto';
import {
    MenuItemDtoFromJSON,
    MenuItemDtoFromJSONTyped,
    MenuItemDtoToJSON,
} from './MenuItemDto';

/**
 * Category with menu items and enabled true
 * @export
 * @interface HomeCategoryDto
 */
export interface HomeCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof HomeCategoryDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HomeCategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof HomeCategoryDto
     */
    language?: string;
    /**
     * 
     * @type {number}
     * @memberof HomeCategoryDto
     */
    menuId?: number;
    /**
     * 
     * @type {string}
     * @memberof HomeCategoryDto
     */
    icon?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HomeCategoryDto
     */
    showOnHomePage?: boolean;
    /**
     * menu items
     * @type {Array<MenuItemDto>}
     * @memberof HomeCategoryDto
     */
    items?: Array<MenuItemDto>;
}

/**
 * Check if a given object implements the HomeCategoryDto interface.
 */
export function instanceOfHomeCategoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function HomeCategoryDtoFromJSON(json: any): HomeCategoryDto {
    return HomeCategoryDtoFromJSONTyped(json, false);
}

export function HomeCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HomeCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'menuId': !exists(json, 'menuId') ? undefined : json['menuId'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'showOnHomePage': !exists(json, 'showOnHomePage') ? undefined : json['showOnHomePage'],
        'items': !exists(json, 'Items') ? undefined : ((json['Items'] as Array<any>).map(MenuItemDtoFromJSON)),
    };
}

export function HomeCategoryDtoToJSON(value?: HomeCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'language': value.language,
        'menuId': value.menuId,
        'icon': value.icon,
        'showOnHomePage': value.showOnHomePage,
        'Items': value.items === undefined ? undefined : ((value.items as Array<any>).map(MenuItemDtoToJSON)),
    };
}

