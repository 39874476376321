import './Language.css';
import {useTranslation} from "react-i18next";
import {languagesMap} from "./LangUtils";


interface LanguageProps{
    setLanguage:(language:string)=>void
}
const Language = ({setLanguage}:LanguageProps) => {
    const {i18n, t} = useTranslation<string, string>();
    const savedLanguage = i18n.language;

    const switchLanguage = ({lng}: { lng: any }) => {
        i18n.changeLanguage(lng);
        setLanguage(languagesMap.get(lng)!!)
    };

    return (
        <div>
            <select id="language" value={savedLanguage} onChange={(e)=>switchLanguage({lng: e.target.value})}>
                <option value="en">🇺🇸 {t("En")}</option>
                <option value="fr">🇫🇷 {t("Fr")}</option>
                <option value="ar">🇸🇦 {t("Ar")}</option>
            </select>
        </div>
    );
};

export default Language;
