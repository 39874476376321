/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountDto } from './DiscountDto';
import {
    DiscountDtoFromJSON,
    DiscountDtoFromJSONTyped,
    DiscountDtoToJSON,
} from './DiscountDto';
import type { IngredientDto } from './IngredientDto';
import {
    IngredientDtoFromJSON,
    IngredientDtoFromJSONTyped,
    IngredientDtoToJSON,
} from './IngredientDto';
import type { TagDto } from './TagDto';
import {
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
} from './TagDto';

/**
 * 
 * @export
 * @interface MenuItemDto
 */
export interface MenuItemDto {
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    description?: string;
    /**
     * 
     * @type {Array<IngredientDto>}
     * @memberof MenuItemDto
     */
    ingredients?: Array<IngredientDto>;
    /**
     * 
     * @type {boolean}
     * @memberof MenuItemDto
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuItemDto
     */
    language?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuItemDto
     */
    categoryId?: number;
    /**
     * 
     * @type {Array<DiscountDto>}
     * @memberof MenuItemDto
     */
    discounts?: Array<DiscountDto>;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof MenuItemDto
     */
    tags?: Array<TagDto>;
}

/**
 * Check if a given object implements the MenuItemDto interface.
 */
export function instanceOfMenuItemDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function MenuItemDtoFromJSON(json: any): MenuItemDto {
    return MenuItemDtoFromJSONTyped(json, false);
}

export function MenuItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'price': json['price'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ingredients': !exists(json, 'ingredients') ? undefined : ((json['ingredients'] as Array<any>).map(IngredientDtoFromJSON)),
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'discounts': !exists(json, 'discounts') ? undefined : ((json['discounts'] as Array<any>).map(DiscountDtoFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagDtoFromJSON)),
    };
}

export function MenuItemDtoToJSON(value?: MenuItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'image': value.image,
        'price': value.price,
        'description': value.description,
        'ingredients': value.ingredients === undefined ? undefined : ((value.ingredients as Array<any>).map(IngredientDtoToJSON)),
        'enabled': value.enabled,
        'language': value.language,
        'categoryId': value.categoryId,
        'discounts': value.discounts === undefined ? undefined : ((value.discounts as Array<any>).map(DiscountDtoToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagDtoToJSON)),
    };
}

