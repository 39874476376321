import styled from "@emotion/styled";
import {DiscountDto} from "../../generated-sources/client";
import {FontIcon} from "@react-md/icon";


const BoxWrapper = styled.div`
    padding: 0 10px;
    height: 20px;
    display: inline-flex;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    background-color: #ffc42e;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    & i {
        font-size: 14px;
    }
`;

interface DiscountParams {
    discount: DiscountDto;
    onClick: () => void;
    className?: string;
}

export function DiscountFlag({className, discount, onClick}: DiscountParams) {
    return (
        <BoxWrapper className={className} onClick={(e)=> { e.stopPropagation(); onClick();}}>
            {discount.rate}% {discount.label}
            <FontIcon style={{marginLeft: "5px"}} role="button"> info_outline</FontIcon>
        </BoxWrapper>
    );
}