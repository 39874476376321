/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MenuItemDto,
  MenuItemsDtoPage,
} from '../models/index';
import {
    MenuItemDtoFromJSON,
    MenuItemDtoToJSON,
    MenuItemsDtoPageFromJSON,
    MenuItemsDtoPageToJSON,
} from '../models/index';

export interface FindMenuItemsByCategoryIdRequest {
    xTenantID: string;
    restaurantId: string;
    categoryId: number;
    language?: string;
    pageNumber?: number;
    pageSize?: number;
}

export interface GetMenuItemsByNameRequest {
    xTenantID: string;
    restaurantId: string;
    name?: string;
    language?: string;
}

/**
 * 
 */
export class MenuItemApi extends runtime.BaseAPI {

    /**
     * get menu items by category id
     */
    async findMenuItemsByCategoryIdRaw(requestParameters: FindMenuItemsByCategoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MenuItemsDtoPage>> {
        if (requestParameters.xTenantID === null || requestParameters.xTenantID === undefined) {
            throw new runtime.RequiredError('xTenantID','Required parameter requestParameters.xTenantID was null or undefined when calling findMenuItemsByCategoryId.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling findMenuItemsByCategoryId.');
        }

        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling findMenuItemsByCategoryId.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantID !== undefined && requestParameters.xTenantID !== null) {
            headerParameters['X-TenantID'] = String(requestParameters.xTenantID);
        }

        const response = await this.request({
            path: `/api/{restaurantId}/category/{categoryId}/menu-items`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))).replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MenuItemsDtoPageFromJSON(jsonValue));
    }

    /**
     * get menu items by category id
     */
    async findMenuItemsByCategoryId(requestParameters: FindMenuItemsByCategoryIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MenuItemsDtoPage> {
        const response = await this.findMenuItemsByCategoryIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get menu items by name
     */
    async getMenuItemsByNameRaw(requestParameters: GetMenuItemsByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MenuItemDto>>> {
        if (requestParameters.xTenantID === null || requestParameters.xTenantID === undefined) {
            throw new runtime.RequiredError('xTenantID','Required parameter requestParameters.xTenantID was null or undefined when calling getMenuItemsByName.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling getMenuItemsByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantID !== undefined && requestParameters.xTenantID !== null) {
            headerParameters['X-TenantID'] = String(requestParameters.xTenantID);
        }

        const response = await this.request({
            path: `/api/{restaurantId}/menu-items`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MenuItemDtoFromJSON));
    }

    /**
     * get menu items by name
     */
    async getMenuItemsByName(requestParameters: GetMenuItemsByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MenuItemDto>> {
        const response = await this.getMenuItemsByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
