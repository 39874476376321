import {DiscountDto} from "../../generated-sources/client";
import styled from "@emotion/styled";
import {useRtl} from "../../hook/useRtl";

interface PriceParams {
    discount?: DiscountDto;
    price: number;
    big?: boolean
}

interface BoxWrapperProps {
    big?: boolean;
}

const BoxWrapper = styled.div<BoxWrapperProps>`
    display: flex;
    gap: 9px;
    align-items: center;

    & .price {
        font-weight: 600;
        font-style: normal;
        font-size: ${props => (props.big ? '20px' : '16px')};
        line-height: ${props => (props.big ? '30px' : '24px')};
        white-space: nowrap;
    }

    & .initial-price {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: line-through;
        position: relative;
        bottom: -1px;
    }

`;

export function Price({discount, price, big}: PriceParams) {
    const {flexStyle} = useRtl();
    function calculatePriceAfterDiscount(price: number, discount: number) {
        return (price * (1 - (discount / 100))).toFixed(2)
    }
    return (
        <BoxWrapper big={big} style={flexStyle}>
            {discount && <div className={'initial-price'}>{price} DT</div>}
            <div className={'price color'}>{discount?.rate ? calculatePriceAfterDiscount(price, discount.rate) : price} DT</div>
        </BoxWrapper>
    );
}