import {TagDto} from "../generated-sources/client";
import {FontIcon} from "@react-md/icon";
import styled from "@emotion/styled";

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    background: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    height: 24px;
    border-radius: 4px;
    padding: 0 8px;
    gap: 4px;
    -webkit-box-shadow: 5px 5px 10px #666;
    -moz-box-shadow: 5px 5px 10px #666;
    box-shadow: 2px 4px 10px #666;
`;

export function Tag(props: { tag: TagDto }) {
    return <Wrapper>
        {props.tag.icon &&
            <FontIcon style={{color: "gold", fontSize: "14px"}}>{props.tag.icon}</FontIcon>}
        <span>{props.tag.name}</span>
    </Wrapper>;
}