import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n';
import {App} from "./App";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <App/>
);

serviceWorkerRegistration.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
        registration?.waiting?.postMessage({type: 'SKIP_WAITING'})
    }
});

reportWebVitals(console.log);
