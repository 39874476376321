/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HomeCategoryDto,
} from '../models/index';
import {
    HomeCategoryDtoFromJSON,
    HomeCategoryDtoToJSON,
} from '../models/index';

export interface FindHomeCategoriesRequest {
    xTenantID: string;
    restaurantId: string;
    language?: string;
}

/**
 * 
 */
export class HomeCategoryApi extends runtime.BaseAPI {

    /**
     * get enabled categories by menu id
     */
    async findHomeCategoriesRaw(requestParameters: FindHomeCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HomeCategoryDto>>> {
        if (requestParameters.xTenantID === null || requestParameters.xTenantID === undefined) {
            throw new runtime.RequiredError('xTenantID','Required parameter requestParameters.xTenantID was null or undefined when calling findHomeCategories.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling findHomeCategories.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantID !== undefined && requestParameters.xTenantID !== null) {
            headerParameters['X-TenantID'] = String(requestParameters.xTenantID);
        }

        const response = await this.request({
            path: `/api/{restaurantId}/categories/home`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HomeCategoryDtoFromJSON));
    }

    /**
     * get enabled categories by menu id
     */
    async findHomeCategories(requestParameters: FindHomeCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HomeCategoryDto>> {
        const response = await this.findHomeCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
