/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    label?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    rate?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountDto
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DiscountDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscountDto
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscountDto
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscountDto
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    language?: string;
}

/**
 * Check if a given object implements the DiscountDto interface.
 */
export function instanceOfDiscountDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DiscountDtoFromJSON(json: any): DiscountDto {
    return DiscountDtoFromJSONTyped(json, false);
}

export function DiscountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function DiscountDtoToJSON(value?: DiscountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'rate': value.rate,
        'active': value.active,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substring(0,10)),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substring(0,10)),
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'language': value.language,
    };
}

