import styled from '@emotion/styled';
import {DiscountDto} from '../../generated-sources/client';
import {useTranslation} from "react-i18next";
import {useRtl} from "../../hook/useRtl";

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
`;

const PopupContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;

    & .description {
        display: flex;
        gap: 5px;
        font-size: 15px;
    }
`;

const CloseButton = styled.button`
    border: none;
    background: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

const DialogTitle = styled.h2`
    margin-top: 0;
`;

interface DiscountInfoPopUpProps {
    closeDialog: () => void;
    discount: DiscountDto
}

export function DiscountInfoPopUp({discount, closeDialog}: DiscountInfoPopUpProps) {
    const {t} = useTranslation<string, string>();
    const {flexStyle} = useRtl();
    const formattedEndTime = discount.endTime ? new Date(discount.endTime).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }) : "";

    return (
        <PopupOverlay onClick={closeDialog}>
            <PopupContent onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={closeDialog}>&times;</CloseButton>
                <DialogTitle>{t('Information')}</DialogTitle>
                <p className={'description'} style={flexStyle}>
                    <span>{t('This discount is available until')}</span>
                    <span>{formattedEndTime}</span>
                </p>
            </PopupContent>
        </PopupOverlay>
    );
}
