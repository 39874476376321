import {createContext} from 'react';

type LanguageContextProps = {
    currentLanguage: string;
    setLanguage: (lang: string) => void,
};
export const LanguageContext = createContext<LanguageContextProps>(
    {} as LanguageContextProps
);


