/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MenuItemDto } from './MenuItemDto';
import {
    MenuItemDtoFromJSON,
    MenuItemDtoFromJSONTyped,
    MenuItemDtoToJSON,
} from './MenuItemDto';

/**
 * 
 * @export
 * @interface MenuItemsDtoPage
 */
export interface MenuItemsDtoPage {
    /**
     * 
     * @type {number}
     * @memberof MenuItemsDtoPage
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItemsDtoPage
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof MenuItemsDtoPage
     */
    totalItems?: number;
    /**
     * 
     * @type {Array<MenuItemDto>}
     * @memberof MenuItemsDtoPage
     */
    content?: Array<MenuItemDto>;
}

/**
 * Check if a given object implements the MenuItemsDtoPage interface.
 */
export function instanceOfMenuItemsDtoPage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MenuItemsDtoPageFromJSON(json: any): MenuItemsDtoPage {
    return MenuItemsDtoPageFromJSONTyped(json, false);
}

export function MenuItemsDtoPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuItemsDtoPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'totalItems': !exists(json, 'totalItems') ? undefined : json['totalItems'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(MenuItemDtoFromJSON)),
    };
}

export function MenuItemsDtoPageToJSON(value?: MenuItemsDtoPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'currentPage': value.currentPage,
        'totalItems': value.totalItems,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(MenuItemDtoToJSON)),
    };
}

