import {useLocation, useNavigate} from "react-router-dom";
import {CategoryDto, MenuItemDto} from "../../generated-sources/client";
import {useContext, useEffect, useState} from "react";
import {findMenuItems} from "../../service/ClientConnect";
import { MenuItemCard } from "../../components/MenuItemCard";
import { LanguageContext } from "../../contexts/LanguageContext";

export function CategoryView() {
    const {state} = useLocation();
    const category = state as CategoryDto;
    const [menuItems, setMenuItems] = useState<MenuItemDto[]>()
    const navigate = useNavigate();
    const {currentLanguage } = useContext(LanguageContext);

    const openDetails = async (menuItem: MenuItemDto) => {
        navigate(`/categories/menuItems/${menuItem.id}/details`, { state: { menuItem: menuItem }});
    }

    useEffect(() => {
        findMenuItems(category.id!!,currentLanguage!!).then(response => setMenuItems(response.content));
    }, [category.id,currentLanguage]);
    return (
        <>
                {menuItems?.map((menuItem) =>
                    <MenuItemCard key={menuItem.id} menuItem={menuItem} style={{marginBottom:"15px"}} onClick={() => openDetails(menuItem)}/>  )}
        </>
    );
}