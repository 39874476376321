import {useNavigate} from 'react-router-dom';
import {Search} from '../../components/Search';
import {HomeCategories} from "./HomeCategories";
import styled from "@emotion/styled";

const AppWrapper = styled.div`
    width: 100%;
`;

function HomeView() {

    const navigate = useNavigate();
    const handleOnClick = () => {
        navigate(`/search`);
    }
    return (
        <AppWrapper>
            <Search onClick={handleOnClick}></Search>
            <div className="categories">
                <HomeCategories></HomeCategories>
            </div>
        </AppWrapper>
    );
}

export default HomeView;
