import {useTranslation} from "react-i18next";

export interface RtlStyle {
    flexStyle: object,
    textStyle: object,
    flexAlignStyle: object
}


const rtlStyles = {
    flexRtlStyle: (direction: String) => {
        let style = {}
        if (direction === "rtl") {
            style = {
                "display":"flex",
                "flexDirection": "row-reverse"
            }
        }
        return style;
    },
    textRtlStyle: (direction: String) => {
        let style = {}
        if (direction === "rtl") {
            style = {
                "textAlign": "right"
            }
        }
        return style;
    },
    flexAlignRtlStyle: (direction: String) => {
        let style = {}
        if (direction === "rtl") {
            style = {
                "alignItems": "flex-end"
            }
        }
        return style;
    }

}

export function useRtl(): RtlStyle {
    const {i18n} = useTranslation<string, string>();
    return {
        flexStyle: rtlStyles.flexRtlStyle(i18n.dir()),
        textStyle: rtlStyles.textRtlStyle(i18n.dir()),
        flexAlignStyle: rtlStyles.flexAlignRtlStyle(i18n.dir())
    };
}