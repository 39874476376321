import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom';
import {saveRestaurantIdentifier} from "../../service/StorageService";

export default function DecoderView() {
    const {encodedIdentifier} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        saveRestaurantIdentifier(String(encodedIdentifier))
        navigate("/")
    }, [encodedIdentifier, navigate]);

    return null
}
