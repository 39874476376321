import styled from "@emotion/styled";
import {apiBasePath} from "../utils/ConfigUtils";
import {FontIcon} from "@react-md/icon";
import {MenuItemDto, TagDto} from "../generated-sources/client";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {DiscountInfoPopUp} from "./discount/DiscountInfoPopUp";
import {Price} from "./price/Price";
import {DiscountFlag} from "./discount/DiscountFlag";
import {getDiscount} from "../utils/DiscountUtils";
import {useRtl} from "../hook/useRtl";
import {Tag} from "./Tag";
import {useLocation, useNavigate} from "react-router-dom";

const BoxWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    & .page-top {
        border-radius: 15px;
        height: 40svh;
        width: 100%;
    }

    & .image {
        position: fixed;
        width: 100%;
        z-index: -2;
    }

    & .tags {
        position: absolute;
        top: 18px;
        right: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        padding-left: 80px;
        flex-direction: row-reverse;
    }

    & .image-bottom {
        position: relative;
        margin-top: -15px;
        background-color: white;
        height: 20px;
        border-radius: 15px;
        z-index: 1;
    }

    & .content {
        padding: 0 20px 20px 20px;
        border-radius: 15px;
        background-color: white;
        overflow-y: scroll;
        height: 75svh;
    }

    & .title {
        font-weight: 600;
        font-family: Sans-serif, Poppins, serif;
        font-size: 20px;
    }

    & .subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    & .description {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        /* Dark Grey */
        color: #282A33;
    }

    & .bottom-card {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0 5px 0;
    }
`;

const StyledArrowLeft = styled(FontIcon)`
    & {
        display: flex;
        align-items: center;
        justify-content: center;

    }
`;

const Ingredient = styled.div`
    background-color: #f1eeee;
    font-size: 0.8rem;
    display: inline-block;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    border: none;
    cursor: pointer;
`;

const ReturnBtn = styled.button`
    position: fixed;
    z-index: 1;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: white;
    color: black;
    border-radius: 12px;
    border: none;
    cursor: pointer;
`;


interface MenuItemDetailsParams {
    style?: any;
    onClick?: any;
    menuItem: MenuItemDto;
}

export function MenuItemDetails({style, onClick, menuItem}: MenuItemDetailsParams) {

    const {t} = useTranslation<string, string>();
    const imgRef = useRef<HTMLImageElement | null>(null);
    const [showDiscountInfo, setShowDiscountInfo] = useState(false);
    const {flexStyle, textStyle} = useRtl();
    const location = useLocation();
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        if (location.state.backSteps) {
            navigate(location.state.backSteps)
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const pageTop = document.getElementById('page-top');
        imgRef.current?.addEventListener('load', () => {
            const imageHeight = imgRef.current?.offsetHeight ?? 0;
            pageTop?.setAttribute('style', `height: ${imageHeight > 500 ? 500 : imageHeight}px`)
        });
    }, []);

    const currentDiscount = getDiscount(menuItem);


    return (
        <BoxWrapper style={style} onClick={onClick}>
            <ReturnBtn onClick={handleBackButtonClick}>
                <StyledArrowLeft>
                    arrow_back
                </StyledArrowLeft>
            </ReturnBtn>
            <div className={'image'}>
                <img ref={imgRef}
                     width={"100%"}
                     src={apiBasePath + menuItem.image}
                     alt={"menu"}
                />
                <div className={'image-bottom'}></div>
                <div className="tags">
                    {menuItem.tags?.map((tag: TagDto) => (
                        <Tag key={tag.id} tag={tag}/>
                    ))}
                </div>
            </div>
            <div id='page-top' className={'page-top'}/>
            <div className={"content"}>
                <div className={"bottom-card"} style={flexStyle}>
                    <div>
                        <div className={"title"} style={textStyle}>{menuItem.name}</div>
                        <Price discount={currentDiscount} price={menuItem.price} big/>
                    </div>
                    {currentDiscount &&
                        <DiscountFlag discount={currentDiscount} onClick={() => setShowDiscountInfo(true)}/>}
                </div>

                <div className={"description"} style={textStyle}>{menuItem.description}</div>
                <div className={"bottom-card"} style={flexStyle}>
                    <p className={"subtitle"}>{t('Ingredients')}</p>
                </div>
                <div style={flexStyle}>
                    {menuItem.ingredients?.map((ingredient: any) => (
                        <Ingredient key={ingredient.id}>
                            {ingredient.name}
                        </Ingredient>
                    ))}
                </div>
            </div>
            {currentDiscount && showDiscountInfo &&
                <DiscountInfoPopUp discount={currentDiscount} closeDialog={() => setShowDiscountInfo(false)}/>}
        </BoxWrapper>
    );
}
