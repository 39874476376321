import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';

function ScrollToAnchor() {
    const location = useLocation();
    const lastScrollY = useRef<number>(0);
    const shouldUpdateScrollPosition = useRef<boolean>(false);
    const lastVisitedPage = useRef<string>('');

    const updateScroll = () => {
        if (shouldUpdateScrollPosition.current) {
            lastScrollY.current = window.scrollY;
        }
    }
    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
        const hash = "category-top-page";
        const scrollTo = lastScrollY.current;
        const hashElement = document.getElementById(hash)
        shouldUpdateScrollPosition.current = hashElement !== null;
        if (hashElement) {
            const resetScroll : boolean =  lastVisitedPage.current === location.pathname;
            lastVisitedPage.current = location.pathname;
            setTimeout(() => {
                if (resetScroll) {
                    window.scrollTo(0, scrollTo);
                } else {
                    if (document.getElementsByClassName("sticky-menu")[0]?.classList.contains("sticky")) {
                        hashElement?.scrollIntoView({behavior: 'auto', block: 'start'});
                    }
                }
                (document.querySelector("nav a.active") as HTMLAnchorElement)
                    ?.scrollIntoView({behavior: "smooth", block: "nearest", inline: "center"});
            }, 200);
        }
    }, [location]);

    useEffect(() => {
        window.addEventListener('scroll', updateScroll );
        return () => {
            window.removeEventListener('scroll', updateScroll)
        }
    }, []);

    return null;
}

export default ScrollToAnchor;
