import {InfoOutlineFontIcon} from "@react-md/material-icons";
import styled from "@emotion/styled";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import DialogContactView from "../../views/contact/DIalogContactView";
import {RestaurantDto} from "../../generated-sources/client";




const ContactWrapper = styled.div`
    margin-right: 10px;
    & .btn-contact {
        box-sizing: border-box;
        padding: 0 10px;
        background-color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        border-radius: 15px;
        font-size: 0.9rem;
    }
    
    & .btn-icon {
        margin-right: 5px;
    }
`;

interface ContactProps {
    restaurant: RestaurantDto;
    buildBackground:()=> string;
}

export function Contact({restaurant, buildBackground}: ContactProps) {
    const {t} = useTranslation<string, string>();
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseContactDialog = () => {
        setIsOpen(false);
    };

    const handleOpenContactDialog = () => {
        setIsOpen(true);
    };
    return (
        <ContactWrapper>
            <div onClick={handleOpenContactDialog} className={'btn-contact'}>
                <InfoOutlineFontIcon className={'btn-icon'}/>
                <span>{t("Contact")}</span>
            </div>
            {isOpen && (
                <DialogContactView restaurant={restaurant!}
                                   onClose={handleCloseContactDialog} buildBackground={buildBackground}/>
            )}
        </ContactWrapper>
    );
}