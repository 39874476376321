import {DiscountDto, MenuItemDto} from "../generated-sources/client";

function isDiscountApplicable(d: DiscountDto) {
    const currentDate = new Date();
    const currentTime = new Date();
    currentTime.setFullYear(d.endTime?.getFullYear()!,d.endTime?.getMonth(),d.endTime?.getDate())
    return  currentDate >= d.startDate! && currentDate <= d.endDate! &&
        currentTime.getTime() >= d.startTime?.getTime()! && currentTime.getTime() <= d.endTime?.getTime()!;
}

export const getDiscount = (menuItem: MenuItemDto) => {
    if (menuItem.discounts) {
        const discounts = menuItem.discounts.filter(d => isDiscountApplicable(d));
        return discounts.length > 0 ? discounts[0] : undefined;
    }
};