import styled from "@emotion/styled"
import React, {ChangeEvent} from "react";
import {SearchFontIcon} from "@react-md/material-icons";
import { useTranslation } from "react-i18next";

const SearchContainer = styled.div`
    display: flex;
    width: 100%;
    max-height:40px
`;

const SearchInput = styled.div`
    width: 100%;
    color: #a9aab4;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    outline: none;
    background-color: rgb(236, 236, 241);
    display: flex;

    & input {
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
    }
`;

interface SearchProps {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    onSubmit?: () => void;
    onClick?:()=>void;
    inputRef?: React.Ref<any>;
}

export function Search({inputRef, onChange, onSubmit,onClick}: SearchProps) {
    const {t} = useTranslation<string, string>();
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            if (onSubmit) {
                onSubmit();
            }
        }
    }

    return (
        <SearchContainer>
            <SearchInput>
                <SearchFontIcon/>
                <input style={{fontSize:'16px'}} ref={inputRef} type="text" placeholder={t("Search")} onChange={onChange} onKeyDown={handleKeyDown} onClick={onClick}/>
            </SearchInput>
        </SearchContainer>
    );
}
