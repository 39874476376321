/* tslint:disable */
/* eslint-disable */
/**
 * digital-menu
 * OpenAPI design for a restaurant web application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RestaurantDto,
  ThemeDto,
} from '../models/index';
import {
    RestaurantDtoFromJSON,
    RestaurantDtoToJSON,
    ThemeDtoFromJSON,
    ThemeDtoToJSON,
} from '../models/index';

export interface GetRestaurantByIdRequest {
    xTenantID: string;
    restaurantId: string;
    language?: string;
}

export interface GetThemeRequest {
    xTenantID: string;
    restaurantId: string;
}

/**
 * 
 */
export class RestaurantApi extends runtime.BaseAPI {

    /**
     * Get restaurant by id
     */
    async getRestaurantByIdRaw(requestParameters: GetRestaurantByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestaurantDto>> {
        if (requestParameters.xTenantID === null || requestParameters.xTenantID === undefined) {
            throw new runtime.RequiredError('xTenantID','Required parameter requestParameters.xTenantID was null or undefined when calling getRestaurantById.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling getRestaurantById.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantID !== undefined && requestParameters.xTenantID !== null) {
            headerParameters['X-TenantID'] = String(requestParameters.xTenantID);
        }

        const response = await this.request({
            path: `/api/{restaurantId}`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestaurantDtoFromJSON(jsonValue));
    }

    /**
     * Get restaurant by id
     */
    async getRestaurantById(requestParameters: GetRestaurantByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestaurantDto> {
        const response = await this.getRestaurantByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get theme
     */
    async getThemeRaw(requestParameters: GetThemeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThemeDto>> {
        if (requestParameters.xTenantID === null || requestParameters.xTenantID === undefined) {
            throw new runtime.RequiredError('xTenantID','Required parameter requestParameters.xTenantID was null or undefined when calling getTheme.');
        }

        if (requestParameters.restaurantId === null || requestParameters.restaurantId === undefined) {
            throw new runtime.RequiredError('restaurantId','Required parameter requestParameters.restaurantId was null or undefined when calling getTheme.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantID !== undefined && requestParameters.xTenantID !== null) {
            headerParameters['X-TenantID'] = String(requestParameters.xTenantID);
        }

        const response = await this.request({
            path: `/api/{restaurantId}/theme`.replace(`{${"restaurantId"}}`, encodeURIComponent(String(requestParameters.restaurantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThemeDtoFromJSON(jsonValue));
    }

    /**
     * get theme
     */
    async getTheme(requestParameters: GetThemeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThemeDto> {
        const response = await this.getThemeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
