import {createContext} from 'react';

interface Features {
   orderEnabled : Boolean
}
type FeaturesContextProps = {
    features: Features;
    setFeatures: (features: Features) => void,
};
const FeaturesContext = createContext<FeaturesContextProps>(
    {} as FeaturesContextProps
);

export {FeaturesContext};
export type { Features };

